<template><div><h1 id="disabling-write-protect" tabindex="-1"><a class="header-anchor" href="#disabling-write-protect"><span>Disabling Write Protect</span></a></h1>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>You do not have to disable write protect if flashing <RouteLink to="/docs/firmware/about.html#rw_legacy">RW_Legacy</RouteLink>.</p>
</div>
<p>Refer to the <RouteLink to="/docs/firmware/supported-devices.html">supported devices list</RouteLink> to figure out which method you need to use to disable write-protect on your device.</p>
<p>Depending on your device, you will need to do <strong>one</strong> of the following:</p>
<ul>
<li>Take out a WP screw</li>
<li><RouteLink to="/docs/firmware/battery.html">Unplug your battery</RouteLink></li>
<li>Bridge two jumpers</li>
<li><RouteLink to="/docs/firmware/suzyq.html">Use a SuzyQable</RouteLink> (A cable that enables CCD (Closed Case Debugging))</li>
</ul>
<div class="custom-container warning"><p class="custom-container-title">WARNING</p>
<p>On devices with a Ti50 security chip (2023+), you will additionally need to <RouteLink to="/docs/firmware/ti50-ap-ro.html">disable RO verification</RouteLink>.</p>
</div>
</div></template>


